var search = {
  init(path) {
    $('.ui.search.accordion').accordion();

    $('.search.button').click( function() {
      $('.ui.search.accordion').accordion("toggle", 0);
    })

    $('.ui.search .dropdown.parameter').dropdown({ 
      fullTextSearch: true,
      onChange(value, text, $selectedItem) {
        search.perform(path)
      }
    });
    
    $('.search .parameter').change( function() {
      search.perform(path)
    })

    search.perform(path)
  },

  collect_parameters() {
    const search_params = {};
    $('.search .parameter').each(function(index, element) {
      if ($(element).hasClass('dropdown')) {
        element = $(element).children().first();
      }
      let value = $(element).val();
      if ($(element).parent().hasClass('checkbox')) {
        value = $(element).prop("checked").toString();
      }
      const id = $(element).attr("id");
      if ((value !== "") && (value !== "false") && (value !== undefined) && (value !== null) && (value.length !== 0) && (value != [""])) {
        if (value.constructor === Array) {
          if (value[0] == "") {
            return true;
          }
        }
        if ((typeof value) === "string") {
          return search_params[id] = value.trim();
        } else {
          return search_params[id] = value;
        }
      }
    });
    return search_params;
  },

  perform(path) {
    $.ajax({
      url: '/' + path,
      type: 'GET',
      dataType: 'script',
      data: search.collect_parameters()
    })
  },

  render_index(namespace, body) {
    $("#" + namespace + " .index").html(body);
    
    search.init_index_for_class(namespace)
  },

  init_index_for_class(class_name) {
    if (window[class_name] !== undefined) {
      if ((typeof window[class_name].init_index) === "function") {
        window[class_name].init_index();
      }
    }
  }
}

window.search = search;
