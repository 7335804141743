$.fn.calendar.settings.formatter.datetime = 'DD.MM.YYYY HH:mm';
$.fn.calendar.settings.formatter.date = 'DD.MM.YYYY',
$.fn.calendar.settings.formatter.time = 'HH:mm';
$.fn.calendar.settings.formatter.cellTime = 'HH:mm';

document.addEventListener("turbo:frame-missing", (event) => {
  const { detail: { response, visit } } = event;
  event.preventDefault();
  visit(response.url);
});

document.addEventListener("turbo:load", function() {
  general.init()
});

var general = {
  init() {
    $('.tooltip').popup({
      delay: {
        show: 800,
        hide: 300
      }
    });
  
    $('.sidebar').sidebar('setting', 'transition', 'overlay')
    .sidebar('attach events', '.main.menu .item.open-sidebar')
  
  
    $('.menu .ui.dropdown').dropdown({ placeholder: false });
    //$('.mein.menu .item').tab();
    
    //$('.ui.error.message').transition('shake');
  
    $('.field_with_errors').each(function() {
      $(this).parent().append($(this).children().detach()).addClass('error');
      return $(this).detach();
    });
  
    time_tracker.time_tracker_popup()
    tasks.task_popup()
    messages.init()

  },

  message_closable() {
    $('.message .close').off('click');
    $('.message .close').on('click', function() {
      $(this).closest('.message').transition('fade');
    });
  },
  
  fields_with_error() {
    $('.field_with_errors').each(function() {
      return $(this).contents().unwrap().parent().addClass('error');
    });
  },

}

window.general = general;
