document.addEventListener("turbo:load", function() {
  if ($("#invoices .index").length > 0) {
    invoices.index()
  }

  if ($("#invoices.edit").length > 0) {
    invoices.edit()
  }

});

var invoices = {
  index() {
    search.init("invoices")
  },

  edit() {
    $('#invoices.form .ui.dropdown').dropdown({ placeholder: false })
    $('.ui.calendar').calendar({ 
      type: 'date',
      monthFirst: false,
      selectAdjacentDays: true,
      formatInput: true,
      firstDayOfWeek: 1,
      formatter: {
        date: function (date, _settings) {
        if (!date) return '';
        var day = ('0' + date.getDate()).slice(-2);
        var month = ('0' + (date.getMonth()+1)).slice(-2);
        var year = date.getFullYear();
        return day + '.' + month + '.' + year;
        }
      }
    })
  }
}

window.invoices = invoices;
