$(document).on('ready page:load', function() {
  let animateError;
  if ($("#go-button").length > 0) {
    autosize($('#leave_message'));
    $('.contact-input').keyup(function(event) {
      if ($('#go-button').data('step') !== 4) {
        if (event.keyCode === 13) {
          $('#go-button').click();
        }
        return;
      }
    });
    
    $('#go-button').click(function(event) {
      switch ($('#go-button').data('step')) {
        case 1:
          $('#contact-slide-1').transition('fade right out');
          $('#contact-slide-2').transition('fade left in');
          $('#go-button').val($('#go-button').data('continue-label')).data('step', 2);
          $('#name').focus();
          $('#back-button').show();
          break;
        case 2:
          if (!$('#name').val()) {
            animateError('#go-button');
            return;
          }
          $('#hidden-name').val($('#name').val());
          $('#contact-slide-2').transition('fade right out');
          $('#contact-slide-3').transition('fade left in');
          $('#email').focus();
          $('#go-button').val($('#go-button').data('continue-label')).data('step', 3);
          break;
        case 3:
          if (!$('#email').val()) {
            animateError('#go-button');
            return;
          }
          $('#hidden-email').val($('#email').val());
          $('#contact-slide-3').transition('fade right out');
          $('#contact-slide-4').transition('fade left in');
          $('#leave-message').focus();
          $('#spacer').html('<br /><br /><br /><br /><br /><br /><br /><br /><br />');
          $('#go-button').val('Submit').data('step', 4);
          break;
        case 4:
          if (!$('#leave-message').val()) {
            animateError('#go-button');
            return;
          }
          $('#hidden-text').val($('#leave-message').val());
          $('#contact').submit();
          break;
      }
    });
    
    $('#back-button').click(function(event) {
      switch ($('#go-button').data('step')) {
        case 2:
          $('#go-button').val($('#go-button').data('start-label')).data('step', 1);
          $('#contact-slide-2').transition('fade left out');
          $('#contact-slide-1').transition('fade right in');
          $('#back-button').hide();
          break;
        case 3:
          $('#go-button').data('step', 2);
          $('#contact-slide-3').transition('fade left out');
          $('#contact-slide-2').transition('fade right in');
          break;
        case 4:
          $('#go-button').val($('#go-button').data('continue-label')).data('step', 3);
          $('#contact-slide-4').transition('fade left out');
          $('#contact-slide-3').transition('fade right in');
          $('#spacer').html('<br /><br /><br /><br /><br /><br />');
          break;
      }
    });
  }
      
  return animateError = button =>
    $(button).addClass('red').delay(500).queue(function(d) {
      $(button).removeClass('red');
      $(button).dequeue();
    })
  ;
});
