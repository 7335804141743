document.addEventListener("turbo:load", function() {
  if ($("#time_track_category_color").length > 0) {
    return $(".ui.dropdown.time-track-category-color-picker").dropdown({
      onChange(value, text, $selectedItem) {
        const color = value.split("#")[1];
        $("#time_track_category_color").val(`#${color}`);
        $(".ui.dropdown.time-track-category-color-picker").css("background-color", `#${color}`);
      }
    });
  }
});
