document.addEventListener("turbo:load", function() {
  if ($("#time_trackers").length > 0) {
    time_tracker.form()
  }
});
      
var time_tracker = {

  form() {
    $('.ui.dropdown').dropdown({placeholder: false});
    
    $('.ui.calendar').calendar({
      type: 'datetime',
      firstDayOfWeek: 1,
      ampm: false
    });
  },

  init_popup(popup) {
    $(".time.tracker.change").change(function() {
      if (($("#time_tracker_description").val() !== "") && ($("#project_id").val() !== "") && ($("#time_track_category_id").val() !== "")) {
        return $(popup).find(".time.tracker.start").removeClass("disabled");
      } else {
        return $(popup).find(".time.tracker.start").addClass("disabled");
      }
    });

    $(popup).find(".time.tracker.start").click(function() {
      $(popup).find(".time.tracker.start").addClass("loading");
      return $.ajax({
        url: '/time_trackers/popup',
        type: 'POST',
        dataType: 'json',
        data: {
          description: $("#time_tracker_description").val(),
          project_id: $("#project_id").val(),
          time_track_category_id: $("#time_track_category_id").val()
        },

        complete() {},

        success(data, textStatus) {
          return time_tracker.tracker_started(popup);
        },

        error(jqXHR, textStatus, errorThrown) {
          console.log(errorThrown);
          return $(popup).find(".time.tracker.start").removeClass("loading");
        }
      });
    });

    $(popup).find(".ui.label.with-popup").popup({
      on: "hover",
      hoverable: true
    });

    $(popup).find(".ui.dropdown.time.tracker.project").dropdown({
      onChange(value, text, $selectedItem) {
        $("#time_tracker_time_track_project_id").val(value);
      }
    });

    $(popup).find(".ui.dropdown.time.tracker.category").dropdown({
      onChange(value, text, $selectedItem) {
        $("#time_tracker_time_track_category_id").val(value);
      }
    });

    return time_tracker.tracker_stop(popup);
  },

  show_timer(popup) {
    return $.ajax({
      url: '/time_trackers/popup',
      type: "GET",
      dataType: 'HTML'
    }).done(function(result) {
      popup.html(result);
      time_tracker.init_popup(popup);

    }).fail(function() {
      popup.html('error');
    });
  },

  tracker_started(popup) {
    console.log("new time tracker started");
    return $.ajax({
      url: '/time_trackers/popup',
      type: 'GET',
      dataType: 'HTML',
      complete() {},

      success(data, textStatus) {
        popup.html(data);
        return time_tracker.tracker_stop(popup);
      },

      error(jqXHR, textStatus, errorThrown) {
        console.log(errorThrown);
        return $(popup).find(".time.tracker.start").removeClass("loading");
      }
    });
  },

  tracker_stop(popup) {
    return $(popup).find(".time.tracker.stop").click(function() {
      $(popup).find(".time.tracker.stop").addClass("loading");
      return $.ajax({
        url: '/time_trackers/popup',
        type: 'POST',
        dataType: 'json',
        data: {
          stop: true
        },

        complete() {},

        success(data, textStatus) {
          return time_tracker.show_timer(popup);
        },

        error(jqXHR, textStatus, errorThrown) {
          console.log(errorThrown);
          return $(popup).find(".time.tracker.stop").removeClass("loading");
        }
      });
    });
  },

  time_tracker_popup() {
    $(".main.menu .time.tracker").popup({
      delay: {
        show: 200
      },
      position: 'bottom center',
      html: '<div style="width: 500px; height: 300px;"><div class="ui active inverted dimmer"><div class="ui text loader">Loading</div></div><p></p></div>',
      on: 'click',
      exclusive: true,
      inline: true,
      offset: -8,
      onShow(el) {
        const popup = this;
        return time_tracker.show_timer(popup);
      }
    });
  }
};

window.time_tracker = time_tracker;
  