document.addEventListener("turbo:load", function() {

  if ($("#users").length > 0) {
    search.init("users")
  }

  if ($("#user").length > 0) {
    //users.edit()
  }
});

var users = {
 edit() {
  $('.ui.dropdown').dropdown({ placeholder: false });

  $(".remove-avatar").click(function() {
    $("#user_remove_avatar").click();
    return $(".avatar-preview").attr('src', $(".avatar-preview").attr("data-default-avatar"));
  });

  $(".add-avatar").click(() => $(".add-avatar-file").click());

  const preview = $(".avatar-preview");
  return $(".add-avatar-file").change(function(event) {
    const input = $(event.currentTarget);
    const file = input[0].files[0];
    const reader = new FileReader();
    reader.onload = function(e) {
      const image_base64 = e.target.result;
      preview.attr("src", image_base64);
    };
    reader.readAsDataURL(file);
  });
 }
}

window.users = users;
