import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    general.fields_with_error()
    general.message_closable()
  }

}
