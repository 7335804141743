import NestedForm from 'stimulus-rails-nested-form'

export default class extends NestedForm {
  connect() {
    super.connect()
  }

  add(event) {
    super.add(event)
    $(".nested-form-wrapper").find(".ui.dropdown").dropdown({ placeholder: false })
    $(".nested-form-wrapper").find(".ui.calendar").calendar({ type: 'date' })
  }
}
