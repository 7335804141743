document.addEventListener("turbo:load", function() {
  if ($("#items").length > 0) {
    items.form()
    search.init("items")
  }
});

var items = {
  form() {
    $('.ui.dropdown').dropdown({ placeholder: false })
  }
}

window.items = items;
