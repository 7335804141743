document.addEventListener("turbo:load", function() {
  if ($("#welcome .master-video").length > 0) {
    const obj = document.querySelector('video')
    obj.play()

    const timer = setTimeout(function() {
      $(".masterhead .huge.button").addClass("pulse")
    }, 3500)
  }
})
