import * as WebAuthnJSON from "@github/webauthn-json"

function getCSRFToken() {
  var CSRFSelector = document.querySelector('meta[name="csrf-token"]')
  if (CSRFSelector) {
    return CSRFSelector.getAttribute("content")
  } else {
    return null
  }
}

function callback(url, body) {
  fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "X-CSRF-Token": getCSRFToken()
    },
    credentials: 'same-origin'
  }).then(function(response) {
    if (response.ok) {
      if (response.url.includes("user_sessions/callback")) {
        post(`/user_sessions/redirect`).then(request=>{
          if(request?.response?.redirected){
            Turbo.visit(request.response.url)
          }  
        })
      } else if (response.url.includes("registration/callback")) {
        $(".passwordless_auth.modal").modal("hide")
        $('body').toast({
          title: 'New Passwordless Device added',
          class: 'success',
        })
      }
    } else if (response.status < 500) {
      response.text().then(
        console.log("test")
      );
    } else {
      console.log("else")
    }
  });
}

function create(callbackUrl, credentialOptions) {
  WebAuthnJSON.create({ "publicKey": credentialOptions }).then(function(credential) {
    callback(callbackUrl, credential);
  }).catch(function(error) {
    console.log("error")
    console.log(error)
  });

  console.log("Creating new public key credential...");
}

function get(credentialOptions) {
  WebAuthnJSON.get({ "publicKey": credentialOptions }).then(function(credential) {
    callback("/user_sessions/callback", credential);
  }).catch(function(error) {
    console.log(error)
  });

  console.log("Getting public key credential...");
}

export { create, get }
