document.addEventListener("turbo:load", function() {
  if ($("#password_reset").length > 0) {
    password_reset.init()
  }
});

var password_reset = {
  init() {
    $("input").on('input', function() {
      password_reset.check()
    })
  },

  check() {
    if (($("#user_password").val() == $("#user_password_confirmation").val()) && ($("#user_password_confirmation").val() != "")) {
      $(".ui.primary.button").removeClass("disabled")
    } else {
      $(".ui.primary.button").addClass("disabled")
    }
  }
};

window.password_reset = password_reset;
