document.addEventListener("turbo:load", function() {
  if ($("#dashboard").length > 0) {
    $('#message-textarea').focus(function() {
      $(this).animate({
        rows: 6
      }, 250);
    });
    
    $('#message-textarea').blur(function() {
      if ($(this).val() == "") {
        $(this).animate({
          rows: 2
        }, 250);
      }
    });  
  }
});
