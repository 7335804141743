import { Controller } from "@hotwired/stimulus"
import * as Credential from "../components/credential";


export default class extends Controller {
  static targets = ["loginField"]

  connect() {
    //console.log("new session")
    super.connect()

    if ($("#webauthn").length > 0) {
      Credential.get($("#webauthn").data("challenge"));
    }
  }
}
