document.addEventListener("turbo:load", function() {
  if ($("#message").length > 0) {
    messages.form()
  }
});

var messages = {
  init() {
    messages.send()
  },

  form() {
    $('.ui.dropdown').dropdown({ placeholder: false });
  },

  send() {
    $(".reply.form .submit.button").click(function () {
      App.message_notifications.perform("post", {message: $('#message-textarea').val()})
      $('#message-textarea').val("")
    })
  }
};

window.messages = messages;
